import { FC, useEffect, useState } from 'react';
import { ContractorStatus } from './components';
import { Modal } from 'antd';
import { gettext } from '@cranium/i18n';
import { CONTRACTOR_STATUS_MODAL } from './components/utils';

export const ContractorStatusModal: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    function handler(event: CustomEvent<boolean>) {
      setOpen(event.detail);
    }
    document.addEventListener(CONTRACTOR_STATUS_MODAL, handler);
    return () => document.removeEventListener(CONTRACTOR_STATUS_MODAL, handler);
  }, []);
  return (
    <Modal
      title={gettext('Account status')}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      destroyOnClose
      footer={null}
      width={800}
    >
      <ContractorStatus />
    </Modal>
  );
};
