import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, Typography } from 'antd';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';

const Container = styled.div<{ width: number }>`
  width: ${props => props.width}px;

  .dropdown {
    width: 100%;
  }

  button:first-child {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
  }
`;

export interface DropdownMenu {
  title: string;
  description: string;
  label: string;
  key: string;
  default?: boolean;
}

interface Props {
  className?: string;
  onClick: (option: string) => void;
  dropdownMenu: DropdownMenu[];
  width?: number;
  loading?: boolean;
  disabled?: boolean;
  danger?: boolean;
}

export const DropdownButton: React.FunctionComponent<Props> = ({
  className,
  onClick,
  dropdownMenu,
  width = 220,
  loading = false,
  disabled = false,
  danger = false
}) => {
  const defaultItem = dropdownMenu.find(item => item?.default) ?? dropdownMenu[0];
  const [currentOption, setCurrentOption] = useState({ title: defaultItem?.title, key: defaultItem?.key });
  useEffect(() => {
    setCurrentOption({ title: defaultItem?.title, key: defaultItem?.key });
  }, [defaultItem?.key]);

  const menuItems = dropdownMenu.map(menuItem => ({
    label: (
      <div className="w-100">
        <Typography className={`semibold-14 ${menuItem.key === currentOption.key ? 'gray-4' : 'gray-9'}`}>
          {menuItem.label}
        </Typography>
        <Typography className="regular-12 gray-7">{menuItem.description}</Typography>
      </div>
    ),
    key: menuItem.key,
    onClick: () => setCurrentOption({ title: menuItem.title, key: menuItem.key })
  }));

  return (
    <Container className={className} width={width}>
      <Dropdown.Button
        loading={loading}
        disabled={disabled}
        type="primary"
        danger={danger}
        overlay={<Menu items={menuItems} />}
        icon={<DownOutlined />}
        className="dropdown"
        overlayStyle={{ width }}
        onClick={() => onClick(currentOption.key)}
      >
        {currentOption.title}
      </Dropdown.Button>
    </Container>
  );
};
