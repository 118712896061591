import { FC } from 'react';
import styled from 'styled-components';

export type BooleanStatusProps = {
  success?: boolean;
};

const Ok = styled.i`
  font-size: 32px;
  color: var(--green-6);
`;
const Bad = styled.i`
  font-size: 32px;
  color: var(--red-6);
`;
export const BooleanStatus: FC<BooleanStatusProps> = ({ success }) => {
  return success ? <Ok className={'icon-check-circle'}></Ok> : <Bad className="icon-close-circle"></Bad>;
};

const OkNew = styled.i`
  font-size: 20px;
  color: var(--green-6);
`;
const BadNew = styled.i`
  font-size: 20px;
  color: var(--warning);
`;

export const BooleanStatusNew: FC<BooleanStatusProps> = ({ success }) => {
  return success ? <OkNew className={'icon-check-circle'}></OkNew> : <BadNew className="icon-warning"></BadNew>;
};
