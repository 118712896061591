import gql from 'graphql-tag';
import { contractor } from '@/core/gql/fragments';

export const contractors = gql`
  query contractors($limit: Int, $skip: Int, $search: String) {
    contractors(limit: $limit, skip: $skip, search: $search) {
      count
      values {
        ...contractor
      }
    }
  }
  ${contractor}
`;

export const contractorsMinimal = gql`
  query contractors($limit: Int, $skip: Int, $search: String, $status: [PartnerContractorStatusEnum!]) {
    contractors(limit: $limit, skip: $skip, search: $search, status: $status) {
      values {
        id
        flags
        displayName
        testMode
      }
    }
  }
`;
