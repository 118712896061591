import React from 'react';
import { Breadcrumb, useUIContext } from '@components/context/ApplicationContext';
import { Space, Typography } from 'antd';
import { Link, useHistory } from '@/shared/navigation';
import styled from 'styled-components';
import { i18n } from '@/core';
import { useGettext } from '@cranium/i18n';
import { usePageTitles } from '@components/Navbar/usePageTitles';
import { UserTip } from '@/shared/tips';

const Container = styled(Space)`
  .breadcrumbs {
    .dasboard-item {
      color: var(--gray-7);
      cursor: pointer;

      :hover {
        color: var(--green-6);
      }
    }

    .icon-right {
      line-height: 1;
      font-size: 25px;
    }
  }
`;

interface BreadcrumbItem {
  path?: string;
  pathText?: React.ReactNode | string;
  text?: React.ReactNode | string;
  customTitle?: React.ReactNode | string;
}

const Breadcrumbs: React.FunctionComponent = () => {
  const history = useHistory();
  const {
    topPanel: { breadcrumb }
  } = useUIContext();
  const { gettext } = useGettext();
  const { getPageTitle } = usePageTitles();

  const { pathname } = history.location;
  const page = pathname?.split('/');
  const pageKey = page[2];
  const pageTitle = getPageTitle(page[2]);

  const buildBreadcrumbs = (breadcrumb: Breadcrumb): BreadcrumbItem[] => {
    const { type, text } = breadcrumb;
    if (type === 'contractorInfo') {
      return [
        {
          path: '/contractors',
          pathText: gettext('Contractors')
        },
        {
          text: text || 'N/A'
        }
      ];
    }
    if (type === 'projectInfo') {
      return [
        {
          path: '/projects',
          pathText: gettext('Projects')
        },
        {
          text: text || 'N/A'
        }
      ];
    }
    if (type === 'orderInfo') {
      return [
        {
          path: '/orders',
          pathText: gettext('Orders')
        },
        {
          text: text || 'N/A'
        }
      ];
    }
    if (type === 'finance') {
      return [
        {
          path: '/finance',
          pathText: gettext('Finance')
        }
      ];
    }
    if (type === 'custom') {
      return [
        {
          customTitle: text
        }
      ];
    }
    return [{}];
  };

  return (
    <Container>
      {breadcrumb.type ? (
        <Space size={5} align="center">
          {buildBreadcrumbs(breadcrumb).map((bc, index) => {
            if (bc.path) {
              return (
                <Space key={index} className="breadcrumbs" size={5} align="center">
                  <Link className="dasboard-item regular-14" to={bc.path}>
                    {bc.pathText}
                  </Link>
                  <span className="icon-right gray-5 regular-20" />
                </Space>
              );
            } else if (bc.text) {
              return <Typography.Text className="regular-14 green-6">{bc.text}</Typography.Text>;
            } else {
              return <Typography className="semibold-24 gray-10 text-capitalize">{bc.customTitle}</Typography>;
            }
          })}
        </Space>
      ) : (
        <UserTip name={pageKey} placement="bottomRight">
          <Typography className="semibold-24 gray-10 text-capitalize">{pageTitle}</Typography>
        </UserTip>
      )}
    </Container>
  );
};

export default Breadcrumbs;
