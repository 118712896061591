import * as Types from '../../../../../gql/types.generated';

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useInfinityDataTemplate, InfinityConfig } from "@/shared/codegen/useInfinityDataTemplate"
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContractorStatusListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContractorStatusListQuery = { __typename?: 'Query', plants: { __typename?: 'PlantList', count: number }, allOrders: { __typename?: 'OrderListExtended', count: number }, paidOrder: { __typename?: 'OrderListExtended', count: number }, partnerPayouts: { __typename?: 'PartnerPayoutList', count: number } };


export const ContractorStatusListDocument = gql`
    query ContractorStatusList {
  plants(limit: 1) {
    count
  }
  allOrders: orders(limit: 1) {
    count
  }
  paidOrder: orders(
    limit: 1
    paymentStatus: [paid, pending, account, partially_paid, failed]
  ) {
    count
  }
  partnerPayouts(limit: 1) {
    count
  }
}
    `;

/**
 * __useContractorStatusListQuery__
 *
 * To run a query within a React component, call `useContractorStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractorStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractorStatusListQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractorStatusListQuery(baseOptions?: Apollo.QueryHookOptions<ContractorStatusListQuery, ContractorStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractorStatusListQuery, ContractorStatusListQueryVariables>(ContractorStatusListDocument, options);
      }
export function useContractorStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractorStatusListQuery, ContractorStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractorStatusListQuery, ContractorStatusListQueryVariables>(ContractorStatusListDocument, options);
        }
export type ContractorStatusListQueryHookResult = ReturnType<typeof useContractorStatusListQuery>;
export type ContractorStatusListLazyQueryHookResult = ReturnType<typeof useContractorStatusListLazyQuery>;
export type ContractorStatusListQueryResult = Apollo.QueryResult<ContractorStatusListQuery, ContractorStatusListQueryVariables>;


export function useContractorStatusListRequest() {
    const client = useApolloClient();
    return useCallback<(variables: ContractorStatusListQueryVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<ContractorStatusListQuery>>>( (variables, options) => {
        return client.query<ContractorStatusListQuery>({
            fetchPolicy: 'no-cache',
          ...options,
          query: ContractorStatusListDocument,
          variables
        })
    }, []);
}


export function useListQueries<DataType = ContractorStatusListQuery, Filters = ContractorStatusListQueryVariables>(options?:InfinityConfig<ContractorStatusListQueryVariables, ContractorStatusListQuery,{}, DataType>){
  return useInfinityDataTemplate<ContractorStatusListQuery, {}, {}, {}, ContractorStatusListQueryVariables, {}, {}, {}, DataType, Filters>({
    list: useContractorStatusListRequest(),
    remove: undefined,
    update: undefined,
    create: undefined,
  }, options)

}

