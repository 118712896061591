import { FC, useEffect, useState, useMemo } from 'react';
import { useListQueries, ContractorStatusListQuery } from './queries/status.generated';
import { usePartnerData } from '@/shared/access';
import { BooleanStatus, BooleanStatusNew } from './BooleanStatus';
import { Table, Button, Spin } from 'antd';
import { ngettext, gettext } from '@cranium/i18n';
import { Link } from '@/shared/navigation';
import styled from 'styled-components';

function transformValue(resp?: ContractorStatusListQuery) {
  return resp;
}

const WEBSITE_URL = process.env.TC_WEBSITE_URL;

const columns = [
  {
    title: gettext('Status'),
    dataIndex: 'status',
    key: 'status',
    width: 80
  },
  {
    title: gettext('Name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: gettext('Description'),
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: gettext('Action'),
    dataIndex: 'action',
    key: 'action',
    width: 150
  }
];

export const ContractorStatus: FC = () => {
  const { data, loading } = useListQueries<ContractorStatusListQuery>({
    transformValue,
    fetchOnMount: true,
    useUrlQuery: false
  });
  const [quickbook, setQuickBook] = useState<{ loading: boolean; hasAccount?: boolean; missingPartner?: boolean }>({
    loading: true,
    hasAccount: false,
    missingPartner: false
  });
  const partnerData = usePartnerData();

  useEffect(() => {
    fetch(`${WEBSITE_URL}/api/quickbooks/check-qbo-connection?partnerId=${partnerData?.id}`)
      .then(res => res.json())
      .then(data => {
        if (data.missingPartner) {
          setQuickBook({ loading: false, missingPartner: true, hasAccount: false });
        } else {
          setQuickBook({ loading: false, missingPartner: false, hasAccount: !!data.success });
        }
      })
      .catch(() => {
        setQuickBook({ loading: false, missingPartner: false, hasAccount: false });
      });
  }, []);

  if (loading || quickbook.loading) {
    return (
      <Center>
        <Spin spinning />
      </Center>
    );
  }

  return (
    <>
      <Block title={gettext('General')}>
        <Row>
          <Name>{gettext('Setup plant')}</Name>
          <Description>
            {data?.plants?.count ? (
              <>
                <BooleanStatusNew success />
                <DescriptionText>
                  {ngettext('You have %(count) plants set up.', 'You have %(count) plants set up.', data?.plants?.count || 0, {
                    count: data?.plants?.count || 0
                  })}
                </DescriptionText>
              </>
            ) : (
              <>
                <BooleanStatusNew success={false} />
                <DescriptionText>
                  {gettext('Please set up at least one plant for the correct usage of the application.')}
                </DescriptionText>
              </>
            )}
          </Description>
          <Action>
            {data?.plants?.count ? (
              ''
            ) : (
              <Link to="/locations-new/new/none" target="_blank">
                <Button type="primary" size="small">
                  {gettext('Create plant')}
                </Button>
              </Link>
            )}
          </Action>
        </Row>
      </Block>
      <Block title={gettext('Order')}>
        <Row>
          <Name>{gettext('Create first order')}</Name>
          <Description>
            {data?.allOrders?.count ? (
              <>
                <BooleanStatusNew success />
                <DescriptionText>
                  {ngettext(
                    'You have already %(count) orders created.',
                    'You have already %(count) orders created.',
                    data?.allOrders?.count || 0,
                    {
                      count: data?.allOrders?.count || 0
                    }
                  )}
                </DescriptionText>
              </>
            ) : (
              <>
                <BooleanStatusNew success={false} />
                <DescriptionText>{gettext('Please create you first order')}</DescriptionText>
              </>
            )}
          </Description>
          <Action>
            {data?.allOrders?.count ? (
              ''
            ) : (
              <Link to="/orders/new" target="_blank">
                <Button type="primary" size="small">
                  {gettext('Create new order')}
                </Button>
              </Link>
            )}
          </Action>
        </Row>
        <Row>
          <Name>{gettext('First order payment')}</Name>
          <Description>
            {data?.paidOrder?.count ? (
              <>
                <BooleanStatusNew success />
                <DescriptionText>
                  {ngettext('You have %(count) paid order', 'You have %(count) paid orders', data?.paidOrder?.count || 0, {
                    count: data?.paidOrder?.count || 0
                  })}
                </DescriptionText>
              </>
            ) : (
              <>
                <BooleanStatusNew success={false} />
                <DescriptionText>{gettext('Get a first order payment')}</DescriptionText>
              </>
            )}
          </Description>
          <Action />
        </Row>
      </Block>
      <Block title={gettext('Payments')} last>
        <Row>
          <Name>{gettext('QuickBooks setup')}</Name>
          <Description>
            {quickbook.missingPartner ? (
              <>
                <BooleanStatusNew success={false} />
                <DescriptionText>{gettext('Please contact support')}</DescriptionText>
              </>
            ) : quickbook.hasAccount ? (
              <>
                <BooleanStatusNew success />
                <DescriptionText>{gettext('QuickBooks integration complete')}</DescriptionText>
                <Action>
                  <a href={`${WEBSITE_URL}/quickbooks/config/general?partnerId=${partnerData?.id || ''}`} target="_blank">
                    <Button type="primary" size="small">
                      {gettext('Go to QuickBooks settings')}
                    </Button>
                  </a>
                </Action>
              </>
            ) : (
              <>
                <BooleanStatusNew success={false} />
                <DescriptionText>
                  {gettext('Please follow the instruction bellow to setup a QuickBooks integration')}
                </DescriptionText>
              </>
            )}
          </Description>
          <Action>
            {!quickbook.missingPartner && !quickbook.hasAccount && (
              <a
                href={`${WEBSITE_URL}/api/quickbooks/connect-to-quickbooks?partnerId=${partnerData?.id || ''}&partnerName=${
                  partnerData?.name || ''
                }`}
                target="_blank"
              >
                <Button type="primary" size="small">
                  {gettext('Setup QuickBooks')}
                </Button>
              </a>
            )}
          </Action>
        </Row>
        <Row>
          <Name>{gettext('First payout archived')}</Name>
          <Description>
            {data?.partnerPayouts?.count ? (
              <>
                <BooleanStatusNew success />
                <DescriptionText>
                  {ngettext('You have %(count) payout', 'You have %(count) payouts', data?.partnerPayouts?.count || 0, {
                    count: data?.partnerPayouts?.count || 0
                  })}
                </DescriptionText>
              </>
            ) : (
              <>
                <BooleanStatusNew success={false} />
                <DescriptionText>{gettext('Get a first payout created')}</DescriptionText>
              </>
            )}
          </Description>
          <Action />
        </Row>
      </Block>
    </>
  );
};

const DescriptionText = styled.span`
  color: var(--gray-8);
  font: var(--font-regular-14);
`;
const Description = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Action = styled.div`
  a {
    display: flex;
    align-items: stretch;
    &:hover {
      text-decoration: none;
    }
    button {
      flex: 1;
    }
  }
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr 20%;
  gap: 12px;
`;

const Name = styled.p`
  color: var(--gray-10);
  text-transform: uppercase;
  font: var(--font-regular-14);
  margin: 0;
`;

const Block: FC<{ title: string | JSX.Element; last?: boolean }> = ({ last, children, title }) => {
  return (
    <Main last={last}>
      <Title>{title}</Title>
      {children}
    </Main>
  );
};

const Main = styled.div<{ last?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
  padding-bottom: ${({ last }) => (last ? '0' : '12px')};
  border-bottom: ${({ last }) => (last ? '0' : '1px solid var(--gray-1)')};
`;

const Title = styled.p`
  color: var(--gray-10);
  text-transform: uppercase;
  font: var(--font-semibold-14);
  margin-bottom: 8px;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;
