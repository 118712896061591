import React from 'react';
import { Avatar, Dropdown, Menu, Space, Typography } from 'antd';
import { useGettext } from '@cranium/i18n';
import { useHistory } from '@/shared/navigation';
import { useMeMember } from '@/core/gql/helpers/hooks';
import { BackgroundOrdersJobService } from '@/core/gql/apollo';
import { LocalStorage } from '@/core/Storage';
import { useHasPermissions, useClientSettings } from '@/shared/access';
import { openContractorsStatusModal } from '@components/ContractorStatusView';

const MemberInfoMenu = () => {
  const canShowProfile = useHasPermissions('permissions.profile.show');
  const canShowStatusPage = useClientSettings()?.partner_status?.visible;
  const { gettext } = useGettext();
  const history = useHistory();
  const member = useMeMember();

  const { pathname } = history.location;
  const { managerName, avatar } = member ?? {};
  const nameInitial = managerName?.split('')[0]?.toUpperCase() ?? 'N/A';

  const menuItems = [
    {
      label: (
        <Space>
          <span className="ant-icon icon-setting gray-7" />
          <Typography className="regular-14 gray-10">{gettext('Settings')}</Typography>
        </Space>
      ),
      key: 'profile',
      permission: canShowProfile,
      onClick: () => pathname !== '/profile' && history.push('/profile')
    },
    {
      permission: canShowStatusPage,
      label: (
        <Space>
          <span className="ant-icon icon-audit gray-7" />
          <Typography className="regular-14 gray-10">{gettext('Status page')}</Typography>
        </Space>
      ),
      key: 'status',
      onClick: () => openContractorsStatusModal()
    },
    {
      label: (
        <Space>
          <span className="ant-icon icon-logout gray-7" />
          <Typography className="regular-14 gray-10">{gettext('Logout')}</Typography>
        </Space>
      ),
      key: 'logout',
      permission: true,
      onClick: () => {
        LocalStorage.clearAll();
        BackgroundOrdersJobService.stopWatch();
      }
    }
  ].filter(item => item?.permission);

  return (
    <Dropdown className="cursor-pointer" overlay={<Menu items={menuItems} />} trigger={['click']}>
      <Space align="center" aria-label={gettext('Profile menu')} role="button">
        <Typography className="bold-14 gray-10">{managerName}</Typography>
        {avatar ? <Avatar size={24} src={avatar} /> : <Avatar size={24}>{nameInitial}</Avatar>}
        <Typography className="icon-down gray-5 regular-24" />
      </Space>
    </Dropdown>
  );
};

export default MemberInfoMenu;
