export const CONTRACTOR_STATUS_MODAL = 'CONTRACTOR_STATUS_MODAL';

declare global {
  interface DocumentEventMap {
    [CONTRACTOR_STATUS_MODAL]: CustomEvent<boolean>;
  }
}

export function openContractorsStatusModal() {
  const event = new CustomEvent(CONTRACTOR_STATUS_MODAL, { detail: true });
  document.dispatchEvent(event);
}

export function closeContractorsStatusModal() {
  const event = new CustomEvent(CONTRACTOR_STATUS_MODAL, { detail: false });
  document.dispatchEvent(event);
}
